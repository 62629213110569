import { useCasinoDeps } from '@st/casino/useDeps'
import type { IconName } from '@st/ui/components/StIcon/types'
import type { Category } from '../../types'

export interface MegaTab {
  link: string
  icon?: IconName
  iconLink?: string | null
  name: string
  isActive?: boolean
  visibleForSidebar?: boolean
}

export interface useCasinoTabsReturn {
  bottomTabs: ComputedRef<MegaTab[]>
  topTabs: ComputedRef<MegaTab[]>
  sidebarTabs: ComputedRef<MegaTab[]>
  isLoading: ComputedRef<boolean>
}

export interface useCasinoTabsParams {
  isLobbyEnabled?: boolean
}

export function useCasinoTabs(
  params: useCasinoTabsParams = {},
): useCasinoTabsReturn {
  const casinoStore = useCasinoStore()
  const { mainCategories, otherCategories, isLoading, isUserHasFreespins } =
    storeToRefs(casinoStore)
  const { getCategoryByCode } = casinoStore
  const { isAuthenticated } = useCasinoDeps()
  const { t } = useI18n()
  const route = useRoute()
  const { includes, startsWith, endsWith } = useRouteMatching()

  const isInMyGames = computed(
    () =>
      includes(route.path, 'casino/favorites') ||
      includes(route.path, 'casino/recent') ||
      includes(route.path, 'casino/freespins'),
  )

  const isInCategories = computed(() =>
    includes(route.path, 'casino/categories'),
  )

  const isInLobby = computed(() => endsWith(route.path, '/casino'))

  const topTabs = computed<MegaTab[]>(() => {
    const result: MegaTab[] = []

    if (isAuthenticated.value) {
      result.push({
        link: '/casino/recent',
        name: t('casino.myGamesTab'),
        icon: 'star-solid',
        isActive: isInMyGames.value,
      })
    }
    result.push({
      link: '/casino',
      name: t('casino.lobby'),
      isActive: isInLobby.value,
      visibleForSidebar: false,
    })
    result.push(
      ...mainCategories.value.map((category: Category) => ({
        link: `/casino/categories/${category.code}`,
        iconLink: category.iconLink || undefined,
        name: category.name || category.code,
        isActive: startsWith(
          route.fullPath,
          `/casino/categories/${category.code}`,
        ),
      })),
    )

    return result
  })

  const sidebarTabs = computed<MegaTab[]>(() => {
    const tabs = topTabs.value.filter((tab) => tab.visibleForSidebar !== false)
    tabs.push({
      link: '/casino/providers',
      name: t('casino.providers'),
      icon: 'providers',
      isActive: startsWith(route.fullPath, '/casino/providers'),
    })
    return tabs
  })
  const bottomTabs = computed<MegaTab[]>(() => {
    const result: MegaTab[] = []

    if (isAuthenticated.value && isInMyGames.value) {
      result.push({
        link: '/casino/recent',
        icon: 'circle-clock-solid',
        name: t('casino.recentGames'),
      })
      result.push({
        link: '/casino/favorites',
        icon: 'heart-solid',
        name: t('casino.favorites'),
      })
      if (isUserHasFreespins.value) {
        result.push({
          link: '/casino/freespins',
          icon: 'freespins',
          name: t('casino.freespins'),
        })
      }
    }
    if (isInCategories.value) {
      const parentCode = route.params.categoryCode
      const parentCategoryLabel = getCategoryByCode(parentCode.toString())
        ?.labelName

      const childCategories = otherCategories.value.filter(
        (category: Category) =>
          category.parentCode === parentCode && category.type === 'other',
      )

      if (params.isLobbyEnabled) {
        result.push({
          link: `/casino/categories/${parentCode}`,
          icon: 'compass-solid',
          name: t('casino.overview'),
        })
      }

      result.push({
        link: `/casino/categories/${parentCode}/all`,
        icon: 'grid-solid',
        name: t('casino.allGames', { category: parentCategoryLabel }),
      })

      result.push(
        ...childCategories.map((category: Category) => ({
          link: `/casino/categories/${parentCode}/${category.code}`,
          iconLink: category.iconLink || undefined,
          name: category.name || category.code,
          isActive: startsWith(
            route.fullPath,
            `/casino/categories/${parentCode}/${category.code}`,
          ),
        })),
      )
    }

    return result
  })

  return {
    topTabs,
    bottomTabs,
    isLoading,
    sidebarTabs,
  }
}
